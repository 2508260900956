@font-face {
    font-family: aktivGroteskBold;
    src: url("/public/fonts/Aktiv-Grotesk-Bold.ttf") format("opentype")
}

@font-face {
    font-family: aktivGroteskRegular;
    src: url("/public/fonts/Aktiv-Grotesk-Regular.ttf") format("opentype")
}

@font-face {
    font-family: interMedium;
    src: url("/public/fonts/Inter-Medium.ttf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: interRegular;
    src: url("/public/fonts/Inter-Regular.ttf") format("opentype");
    font-display: swap;
}


#root {
    font-family: aktivGroteskRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: normal;
    line-height: normal;
}

body {
    font-family: aktivGroteskRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.05 !important;
    letter-spacing: -1px;
}

.homepage-did-you-know-card-description,
.reports-menu,
.document-card-name,
.document-card-info,
.documents-section-card-title,
.contact-profile-picture-letters,
.bank-account-id-card,
.blog-article-card-category,
.guide-article-card-category,
.login-form-element-label,
.login-page-contact-info,
.login-page-no-account,
.verify-resend-code,
.return-to-details,
.two-factor-no-text,
.hide-transaction-dialogue-title,
.add-contact-have-access-now-text,
.add-contact-have-access-later-text
{
    line-height: 21px;
}

.report-description,
.feature-card-text-container,
.hide-transaction-dialogue-cancel-button,
.hide-transaction-dialogue-hide-button,
.hide-transaction-dialogue-link,
.hide-transaction-dialogue-body,
.delete-account-dialogue-link,
.delete-contact-dialogue-body,
.delete-document-dialogue-body,
.delete-account-dialogue-body,
.account-dialog-delete-button,
.account-dialog-value,
.account-dialog-label,
.add-transaction-form-row-input-large textarea,
.add-transaction-form-row-input input,
.add-transaction-form-dropdown,
.add-transaction-form-add-button,
.add-transaction-form-description,
.contact-card-relationship,
.contact-card-email,
.contact-card-phone-number,
.add-contact-form-dropdown,
.transaction-table-something-missing-add,
.transaction-table-something-missing-label,
.bank-account-provider-card,
.side-nav-sub-link,
.bank-accounts-refresh-button i,
.bank-accounts-refresh-button-text,
.bank-accounts-add-button-text,
.landing-popup-description,
.blog-description,
.guide-description,
.blog-article-card-description,
.guide-article-card-description,
.blog-article-body,
.guide-article-body,
.login-image-quoter,
.login-image-quoter-description,
.login-page-subtitle,
.register-page-subtitle,
.login-error-message,
.register-section-title,
.register-section-description,
.register-page-description,
.mfa-page-description,
.register-access-now-label,
.register-plus-feature-text,
.delete-document-dialogue-cancel-button,
.delete-document-dialogue-hide-button,
.delete-contact-dialogue-cancel-button,
.delete-contact-dialogue-hide-button,
.delete-account-dialogue-cancel-button,
.delete-account-dialogue-hide-button,
.privacy-body-container,
.popup-description
{
    line-height: 24px;
}

.report-download-button,
.home-page-partnership-card-description,
.home-page-partnerships-description,
.home-page-pricing-description,
.home-page-features-description,
.home-page-landing-description,
.login-form-submit-container input,
.register-form-submit-container input,
.delete-document-dialogue-title,
.delete-contact-dialogue-title,
.delete-account-dialogue-title,
.account-cancel-changes
{
    line-height: 28px;
}

.home-page-price-card-type,
.reports-subpage-navigation-title,
.transaction-details-dialogue-row-value,
.transaction-details-dialogue-row-label,
.transaction-details-dialogue-heading,
.delete-account-dialogue-title,
.add-transaction-form-row-input-large,
.add-transaction-form-row-label,
.add-transaction-form-row-label-large,
.add-transaction-form-row-input,
.add-transaction-form-heading,
.documents-section-info-description,
.documents-section-label,
.contacts-page-contacts-container,
.table-body-cell,
.table-row-container,
.bank-accounts-title,
.side-nav-main-link,
.blog-article-card-title,
.guide-article-card-title {
    line-height: 30px;
}

.home-page-our-story-text,
.bank-account-provider,
.add-document-title,
.account-details-title,
.security-details-title,
.add-contact-title,
.transaction-table-title,
.feedback-submit-button,
.feedback-cancel-button,
.profile-membership-details-title {
    line-height: 36px;
}

.profile-page-title,
.documents-section-info-title,
.contact-page-title,
.landing-popup-title {
    line-height: 45px;
}

.login-image-quote span,
.login-image-quote {
    line-height: 51px;
}

.documents-banking-go-to-text,
.home-page-footer-copyright-container,
.get-started-banner-description,
.nav-bar-register,
.nav-bar-login,
.feature-card-text-container,
.home-page-features-description,
.document-card-info,
.hide-transaction-dialogue-body,
.transaction-details-dialogue-row-value,
.transaction-details-dialogue-row-label,
.delete-account-dialogue-link,
.delete-document-dialogue-body,
.delete-contact-dialogue-body,
.delete-account-dialogue-body,
.account-dialog-value,
.account-dialog-label,
.add-transaction-form-row-input-large textarea,
.add-transaction-form-row-input input,
.add-transaction-form-dropdown-items,
.add-transaction-form-dropdown,
.add-transaction-form-row-input-large,
.add-transaction-form-row-label,
.add-transaction-form-row-label-large,
.add-transaction-form-row-input,
.add-transaction-form-description,
.contact-card-relationship,
.contact-card-email,
.contact-card-phone-number,
.add-contact-form-dropdown,
.transaction-table-something-missing-add,
.transaction-table-something-missing-label,
.table-body-cell,
.table-row-container,
.report-download-button,
.report-description,
.reports-menu,
.documents-section-info-description,
.documents-section-label,
.contacts-page-contacts-container,
.landing-popup-description,
.landing-popup-returning,
.landing-popup-new-button,
.blog-description,
.guide-description,
.blog-article-card-description,
.guide-article-card-description,
.blog-article-body,
.guide-article-body,
.login-image-quoter,
.login-image-quoter-description,
.register-page-description,
.register-access-now-label,
.login-page-description,
.login-form-element-label,
.login-form-element-password-container input,
.login-form-element-container input,
.register-form-element-password-container input,
.register-form-element-container input,
.login-page-no-account,
.login-page-contact-info,
.login-error-message,
.register-section-description,
.verify-form-character,
.verify-resend-code,
.return-to-details,
.two-factor-no-text,
.free-plan-button,
.delete-document-dialogue-title,
.delete-contact-dialogue-title,
.delete-account-dialogue-title,
.register-plus-feature-text,
.delete-document-dialogue-cancel-button,
.delete-document-dialogue-hide-button,
.delete-contact-dialogue-cancel-button,
.delete-contact-dialogue-hide-button,
.delete-account-dialogue-cancel-button,
.delete-account-dialogue-hide-button,
.hide-transaction-dialogue-title,
.guide-article-category,
.more-guide-text,
.add-contact-have-account-message,
.add-contact-permissions-title,
.add-contact-have-access-now-text,
.add-contact-have-access-later-text,
.contact-page-add-button,
.privacy-body-container,
.popup-description,
.feedback-complete,
.feedback-error,
.invite-error,
.invite-others-form-element-container input,
.invite-others-form-button,
.profile-plus-payment-information,
.plus-subscription-banner-description
{
    font-family: aktivGroteskRegular;
}

.home-page-landing-title,
.home-page-landing-description,
.home-page-features-title,
.home-single-report-container,
.homepage-did-you-know-container,
.home-page-pricing-container,
.home-page-our-story-card-container,
.home-page-mentioned-press-container,
.home-page-our-technology-container,
.home-page-get-started-get-started-banner,
.home-page-partnerships-container,
.home-page-faq-container,
.home-page-contact-container,
.home-page-footer-container,
.home-page-footer-copyright-container,
.homepage-dynamic-banner-item,
.homepage-dynamic-banner-text-static,
.plus-subscribe-button,
.register-plus-yearly-container,
.register-plus-period-container,
.plus-subscription-cancel-anytime {
    font-family: interRegular;
}

.home-page-features-title,
.homepage-did-you-know-card-title,
.home-page-our-technology-title,
.get-started-banner-title,
.home-page-contact-title,
.footer-link-title,
.register-plus-price {
    font-weight: 600;
}

.home-page-technology-card-title {
    font-weight: 700;
}

.home-page-features-title {
    line-height: 62px;
}

.documents-subpage-title,
.home-page-our-story-image-name,
.document-card-name,
.hide-transaction-dialogue-cancel-button,
.hide-transaction-dialogue-hide-button,
.account-dialog-delete-button,
.add-transaction-form-add-button,
.contact-profile-picture-letters,
.bank-accounts-add-button-text,
.bank-account-id-card,
.bank-account-provider-card,
.bank-accounts-refresh-button i,
.bank-accounts-refresh-button-text,
.feature-card-text-container b,
.profile-page-title,
.reports-subpage-navigation-title,
.transaction-details-dialogue-heading,
.bank-account-provider,
.add-transaction-form-heading,
.documents-section-info-title,
.documents-section-card-title,
.add-document-title,
.account-details-title,
.security-details-title,
.add-contact-title,
.contact-page-title,
.swiper-button-next:after,
.swiper-button-prev:after,
.transaction-table-title,
.bank-accounts-title,
.side-nav-main-link,
.side-nav-sub-link,
.landing-popup-title,
.blog-heading,
.guide-heading,
.blog-article-card-category,
.guide-article-card-category,
.blog-article-card-title,
.guide-article-card-title,
.blog-article-title,
.blog-article-category,
.blog-article-body h2,
.guide-article-body h2,
.login-image-quote span,
.login-image-quote,
.login-page-title,
.register-page-title,
.mfa-page-title,
.login-page-subtitle,
.register-page-subtitle,
.mfa-page-subtitle,
.login-form-submit-container input,
.register-form-submit-container input,
.register-section-title,
.hide-transaction-dialogue-link,
.guide-article-title,
.executors-title,
.account-cancel-changes,
.privacy-title-container,
.guide-article-card-read-article,
.popup-title,
.feedback-submit-button,
.feedback-cancel-button,
.invite-others-form-element-label,
.profile-membership-type,
.profile-membership-type-label,
.profile-plus-upgrade,
.profile-membership-details-title,
.plus-subscription-banner-title
{
    font-family: aktivGroteskBold;
}

.documents-subpage-title,
.homepage-dynamic-banner-item,
.homepage-dynamic-banner-text-static,
.report-description,
.home-page-contact-title,
.get-started-banner-title,
.profile-page-title,
.reports-menu,
.reports-subpage-navigation-title,
.document-card-name,
.document-card-info,
.hide-transaction-dialogue-cancel-button,
.hide-transaction-dialogue-hide-button,
.hide-transaction-dialogue-link,
.hide-transaction-dialogue-body,
.transaction-details-dialogue-row-value,
.transaction-details-dialogue-row-label,
.transaction-details-dialogue-heading,
.delete-contact-dialogue-cancel-button,
.delete-contact-dialogue-hide-button,
.delete-document-dialogue-cancel-button,
.delete-document-dialogue-hide-button,
.delete-account-dialogue-cancel-button,
.delete-account-dialogue-hide-button,
.delete-account-dialogue-link,
.delete-contact-dialogue-body,
.delete-document-dialogue-body,
.delete-account-dialogue-body,
.account-dialog-delete-button,
.account-dialog-value,
.account-dialog-label,
.bank-account-provider,
.add-transaction-form-add-button,
.add-transaction-form-row-input-large,
.add-transaction-form-row-label,
.add-transaction-form-row-label-large,
.add-transaction-form-row-input,
.add-transaction-form-description,
.add-transaction-form-heading,
.documents-section-info-description,
.documents-section-info-title,
.documents-section-label,
.documents-section-card-title,
.contact-card-relationship,
.contact-card-email,
.contact-card-phone-number,
.contact-profile-picture-letters,
.add-doucment-title,
.add-contact-title,
.contacts-page-contacts-container,
.contact-page-add-button,
.contact-page-title,
.transaction-table-something-missing-add,
.transaction-table-something-missing-label,
.table-body-cell,
.table-row-container,
.transaction-table-title,
.bank-account-id-card,
.bank-account-provider-card,
.bank-accounts-refresh-button i,
.bank-accounts-refresh-button-text,
.bank-accounts-add-button-text,
.bank-accounts-title,
.landing-popup-description,
.landing-popup-title,
.landing-popup-returning,
.landing-popup-new-button,
.documents-banking-go-to-text,
.blog-heading,
.guide-heading,
.blog-description,
.guide-description,
.blog-article-card-category,
.guide-article-card-category,
.blog-article-card-title,
.guide-article-card-title,
.blog-article-card-description,
.guide-article-card-description,
.blog-article-body,
.blog-article-body h2,
.guide-article-body,
.guide-article-body h2,
.blog-article-title-container h1,
.blog-article-category,
.login-image-quote span,
.login-image-quote,
.login-image-quoter,
.login-image-quoter-description,
.register-page-description,
.register-access-now-label,
.login-page-description,
.login-page-subtitle,
.register-page-subtitle,
.login-form-element-label,
.login-form-element-password-container input,
.login-form-element-container input,
.register-form-element-password-container input,
.register-form-element-container input,
.login-page-no-account,
.login-page-contact-info,
.login-error-message,
.register-section-description,
.register-section-title,
.verify-resend-code,
.return-to-details,
.two-factor-no-text,
.free-plan-button,
.register-plus-feature-text,
.delete-document-dialogue-body,
.delete-contact-dialogue-body,
.delete-account-dialogue-body,
.delete-document-dialogue-title,
.delete-contact-dialogue-title,
.delete-account-dialogue-title,
.hide-transaction-dialogue-title,
.guide-article-category,
.guide-article-title,
.more-guide-text,
.add-contact-have-account-message,
.add-contact-permissions-title,
.add-contact-have-access-now-text,
.add-contact-have-access-later-text,
.executors-title,
.privacy-title-container,
.privacy-body-container,
.guide-article-card-read-article,
.feedback-error,
.invite-error,
.invite-others-form-element-label,
.invite-others-form-element-container input,
.invite-others-form-button,
.register-plus-period,
.register-plus-type,
.plus-subscription-banner-description,
.plus-subscription-banner-title
{
    letter-spacing: -0.011em;
}

.login-form-submit-container input,
.register-form-submit-container input,
.account-cancel-changes,
.popup-title,
.popup-description,
.feedback-complete,
.feedback-submit-button,
.feedback-cancel-button,
.profile-membership-type,
.profile-membership-type-label
{
    letter-spacing: -0.2px;
}

.home-page-our-story-image-name {
    letter-spacing: -0.5px;
}

.homepage-did-you-know-title {
    letter-spacing: -1.2px;
}

.login-page-title,
.register-page-title,
.mfa-page-title,
.homepage-did-you-know-card-title,
.home-page-features-title{
    letter-spacing: -2px;
}

.footer-link-title {
    letter-spacing: 1px;
    text-transform: uppercase;
}

.home-single-report-card-title,
.home-page-faq-title{
    letter-spacing: 2px;
}