@media only screen and (max-width: 1115px) {
  .side-nav-container,
  .login-page-image-container {
    display: none;
  }

  .dashboard-subpage-container,
  .home-subpage-container,
  .outgoings-subpage-container,
  .contacts-subpage-container,
  .executors-subpage-container,
  .privacy-subpage-container,
  .documents-subpage-container,
  .reports-subpage-container,
  .profile-subpage-container,
  .knowledge-hub-subpage-container,
  .guide-subpage-container {
    margin: auto;
    width: calc(100vw - 40px);
    padding: 0;
  }

  .login-page-form-container,
  .register-page-form-container,
  .mfa-subpage-container {
    height: 100vh;
    width: 100vw;
    margin: auto;
    padding: 20px;
  }

  .register-page-progress-container {
    display: none;
  }

  .register-page-verification-container {
    width: 100% !important;
    padding: 10% 5%;
  }

  .register-page-subtitle.two-factor,
  .register-page-description.two-factor {
    text-align: center;
  }

  .register-form {
    text-align: center;
    margin: auto;
  }

  .register-form-element-label {
    text-align: left;
  }

  .register-page-title {
    text-align: center;
  }

  .register-page-subpage-container {
    width: 100%;
    padding: 15% 5%;
  }

  .free-plan-button-container {
    margin: 30px auto;
    text-align: center;
  }

  .free-plan-button.link {
    margin-left: 0px;
  }

  .register-page-advanced-container {
    margin: 20px auto;
    padding-bottom: 0px;
  }

  .two-factor-button-container {
    text-align: center;
    margin: auto;
    display: block;
  }

  .two-factor-no-text.link {
    margin-left: 0px;
  }

  .verify-form-container {
    padding: 0px;
    margin-left: 4%;
  }

  .reset-form-container {
    padding: 0px;
    margin-left: 0;
  }

  .register-subpage-container {
    min-height: 100vh;
  }

  .document-banking-empty,
  .document-banking-section-card-link,
  .dashboard-col {
    /*width: 64px !important;*/
    padding: 0px !important;
    margin: auto;
  }

  .dashboard-knowledge-hub-container .row .col {
    width: fit-content;
    margin: auto;
    margin-bottom: 24px;
  }

  .guide-article-card-container {
    margin: auto;
    min-width: 280px;
  }

  .documents-section-card {
    /*margin-top: 0.5rem !important;*/
    width: 100%;
    height: 100%;
    min-width: 112px;
    min-height: 140px;
    /*width: 100px;*/
    /*margin-right: 0px;*/
    /*height: 140px;*/
    /*background: none !important;*/
    /*box-shadow: none !important;*/
  }

  .documents-section-card-image {
    width: 54px;
    height: 54px;
  }

  R /*.documents-section-card {*/
    /*  width: 64px;*/
    /*}*/

    .documents-assets-section-cards-container {
    width: calc(100vw - 40px);
    margin-left: 0px;
    margin-right: 0px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(112px, 1fr));
    gap: 0.4rem;
    min-width: 0px;
  }

  .documents-assets-section-cards-container.link.row .link {
    padding: 0px !important;
    margin: auto;
  }

  .documents-assets-section-cards-container .col {
    padding-left: 2vw;
    width: fit-content;
  }

  .documents-section-card-image-container {
    width: 64px;
    height: 64px;
  }

  .documents-section-card-title {
    font-size: 13px;
    bottom: 1.4rem;
    padding: 0;
  }

  .documents-section-card-title + p {
    font-size: 0.8rem;
  }

  .header-container {
    background: transparent !important;
  }

  .header-logo-container {
    padding: 24px 20px;
  }

  .large-header {
    display: none;
  }

  .small-header {
    display: block;
    padding-top: 20px;
  }

  .home-search-container {
    display: flex;
  }

  .header-content {
    display: flex;
  }

  .header-logo-container {
    width: calc(100vw - 54px);
  }

  .header-link-container .nav-links {
    position: absolute;
    right: -2vw;
    top: 0;
    width: 100vw;
    z-index: 99;
  }

  .burger-line {
    display: block;
    height: 5px;
    width: 100%;
    margin-top: 5px;
    border-radius: 10px;
  }

  .burger-icon.active .line1,
  .burger-icon.active .line2,
  .burger-icon.active .line3 {
    display: none;
  }

  .burger-icon.active .burger-cross {
    display: flex;
  }

  .burger-links-container {
    margin-bottom: 80px;
    margin-top: 30px;
  }

  .burger-icon {
    width: 30px;
    height: 25px;
    margin-right: 14px;
  }

  .burger-menu {
    height: 100vh;
    width: 97vw;
    min-width: 97vw;
    position: fixed;
    z-index: 100;
    opacity: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    overflow: scroll;
    max-height: 100vh;
  }

  .burger-menu.open {
    transform: translateX(5px);
    -webkit-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -moz-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -ms-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -o-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }

  .burger-menu.closed {
    transform: translateX(1115px);
    -webkit-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -moz-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -ms-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    -o-transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    transition: transform 0.6s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }

  .burger-menu-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 0 25px;
    min-height: 100px;
    z-index: 102;
  }

  .burger-menu-body-container {
    padding: 0 25px 100px 25px;
    z-index: 102;
  }

  .burger-menu-sections-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .burger-menu-divider {
    margin: 20px 0;
  }

  .burger-item {
    font-size: 24px;
    padding: 20px 10px;
  }

  .burger-menu-body-container a {
    text-decoration: none;
  }

  .documents-subpage-title {
    margin-top: 30px;
    font-size: 30px;
  }

  .documents-banking-section-container {
    margin-top: 10px;
  }

  .contact-page-container,
  .individual-contact-page-container,
  .documents-section-info-container {
    margin-top: 20px;
  }

  .contact-page-add-button {
    margin-left: 0;
    height: 35px;
    min-width: 60px;
    width: 60px;
    font-size: 18px;
    padding: 7px;
  }

  .contacts-page-contacts-grid,
  .contacts-page-contacts-grid .row,
  .add-contact-form-input,
  .add-contact-form-element-container-multiple,
  .documents-section-info-container {
    width: 100%;
  }

  .contact-card {
    max-width: 100%;
    min-width: 230px;
    margin: 20px 10px;
    padding: 0 !important;
    border-radius: 5px;
  }

  .add-document-container,
  .add-contact-container {
    min-width: unset;
    max-width: unset;
    background: transparent !important;
  }

  .add-document-form-description-container,
  .add-document-form-element-container,
  .add-contact-form-element-container,
  .add-document-form-element-container-multiple,
  .add-contact-form-element-container-multiple,
  .add-contact-buttons-container {
    margin: 10px 0;
  }

  .profile-form-row-container,
  .add-document-form-row-container,
  .add-contact-form-row-container,
  .add-contact-component-container,
  .add-document-form-upload-container {
    margin: 0;
  }

  .profile-form-row-container,
  .add-document-form-row-container,
  .add-contact-form-row-container {
    display: block;
  }

  .add-contact-have-access-later-container,
  .add-contact-have-access-now-container,
  .add-contact-permissions-title,
  .add-contact-have-account-message {
    padding: 10px 0;
  }

  .add-contact-delete-button,
  .add-document-delete-button,
  .add-document-cancel-button {
    width: 50%;
    text-align: center;
    margin-left: 10px;
  }

  .add-document-buttons-container,
  .add-contact-buttons-container {
    padding-bottom: 40px;
    margin: 0;
  }

  .add-contact-have-access-later-container input,
  .add-contact-have-access-now-container input {
    min-width: 22px;
  }

  .add-document-title,
  .add-contact-title {
    padding: 20px 0;
  }

  .documents-section-container {
    display: block;
    margin-top: 40px;
    position: relative;
  }

  .documents-add-button {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 80px;
  }

  .documents-page-breadcrumb {
    font-size: 18px;
  }

  .documents-section-info-title {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .document-card-container {
    margin-bottom: 30px;
  }

  .document-card-text-container {
    margin-left: 20px;
  }

  .bank-accounts-container {
    border-radius: 5px;
    height: unset;
  }

  .bank-accounts-top-container {
    padding: 5px;
  }

  .bank-accounts-add-button-text,
  .bank-accounts-refresh-button-text {
    display: none;
  }

  .bank-accounts-add-button,
  .bank-accounts-refresh-button {
    border-radius: 5px;
    margin: 5px;
    width: 40px;
    height: 40px;
    background-color: #009dfd;
    border: none !important;
  }

  .bank-accounts-add-button {
    right: 10px;
  }

  .bank-accounts-refresh-button {
    right: 60px;
  }

  .bank-accounts-add-button img,
  .bank-accounts-refresh-button img {
    top: 9px;
    left: 11px;
  }

  .bank-accounts-title {
    left: 20px;
  }

  .bank-accounts-cards-container {
    display: none;
  }

  .mobile-accounts-cards-container {
    display: block;
    padding-bottom: 30px;
  }

  .mobile-account-row {
    display: flex;
    margin: 10px;
  }

  .mobile-account-logo {
    height: 44px;
    width: 44px;
    max-width: 44px;
    border-radius: 50%;
    margin: 0 10px;
  }

  .mobile-account-logo img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .mobile-account-number {
    text-align: end;
    padding: 0 5px;
  }

  .bank-accounts-bottom-container {
    height: fit-content;
  }

  .account-dialog-component {
    width: 100vw;
  }

  .table-header-cell-container.bank,
  .table-body-cell.bank {
    display: none;
  }

  .table-header-cell-container.date,
  .table-body-cell.date {
    display: none;
  }

  .table-header-cell-container.name,
  .table-body-cell.name {
    width: 70%;
    max-width: 70%;
    overflow-y: hidden;
    margin-right: 5px;
    padding-left: 0;
  }

  .transaction-table-title {
    padding: 20px 0;
    margin: 0;
  }

  .table-body-cell {
    font-size: 16px;
  }

  .transaction-table-total-amount-container {
    width: 100%;
    margin: 0;
  }

  .transaction-table-something-missing-container {
    width: 100%;
    margin: 0;
  }

  .add-transaction-form-container {
    position: absolute;
    top: 0;
    left: 0;
    height: unset;
    width: 100%;
    margin: 0 !important;
    border-radius: 0;
  }

  .add-transaction-form-top-container {
    width: calc(100% - 40px);
    margin: auto;
    height: unset;
  }

  .add-transaction-form-heading {
    width: unset;
  }

  .transaction-table-container,
  .executors-table-container {
    margin: 0;
    width: 100%;
    border: none !important;
  }

  .add-transaction-form-top {
    position: relative;
  }

  .add-transaction-form-close {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 0;
  }

  .add-transaction-form-row-container,
  .add-transaction-form-row-container-large {
    width: calc(100% - 40px) !important;
    margin: auto;
    display: block;
  }

  .add-transaction-form-row-label,
  .add-transaction-form-row-label-large {
    margin: 20px 0;
    width: 100% !important;
  }

  .add-transaction-form-row-input,
  .add-transaction-form-row-input-large {
    margin: 10px 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .add-transaction-form-add-button-container {
    width: 249px !important;
    margin: 40px auto;
    height: unset;
  }

  .add-transaction-form-add-button {
    margin: 0;
  }

  .add-contact-form-input select,
  .add-transaction-form-row-input select {
    min-width: 0;
    min-height: 0;
    overflow: hidden;
  }

  .transaction-table-element-container,
  .table-headers-container {
    background: transparent !important;
  }

  .transaction-table-title-container {
    height: unset;
  }

  .transaction-details-dialogue {
    width: calc(100% - 20px);
    top: 10px;
    left: 10px;
    height: unset;
    padding: 20px !important;
  }

  .transaction-details-dialogue-heading {
    width: unset;
    margin: 0;
  }

  .transaction-details-dialogue-top-container {
    position: relative;
  }

  .transaction-details-dialogue-top-container,
  .transaction-details-dialogue-row-container,
  .transaction-details-dialogue-row-label,
  .transaction-details-dialogue-row-value {
    width: 100% !important;
    margin: 10px 0 !important;
    text-align: left;
  }

  .transaction-details-dialogue-row-label {
    font-weight: bolder;
  }

  .transaction-details-dialogue-row-container {
    display: block;
  }

  .transaction-details-dialogue-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
  }

  .reports-subpage-navigation-container {
    width: 100%;
    height: unset;
    padding: 20px 0;
  }

  .reports-subpage-navigation-title {
    margin: 0 20px;
  }

  .reports-menu {
    margin: 0 20px;
  }

  .reports-subpage-preview-container {
    display: none;
    margin: 0;
    background: transparent !important;
  }

  .report-viewer {
    padding: 0;
  }

  .report-transparent-cover {
    position: unset;
    padding: 220px 0 0;
    background: transparent !important;
    width: calc(100% - 40px);
    margin-left: 20px;
    backdrop-filter: none !important;
  }

  .dialog-container {
    width: calc(100% - 20px);
    left: 10px;
  }

  .your-executors-container,
  .profile-form-container,
  .security-form-container,
  .profile-membership-container {
    background: transparent !important;
  }

  .executors-title-container {
    padding: 0;
  }

  .table-header-cell-container.full-name,
  .table-body-cell.full-name {
    padding-left: 0;
    text-align: left;
  }

  .table-body-cell-full-name {
    margin: 0;
  }

  .profile-page-title {
    margin-top: 10px;
  }

  .account-details-title,
  .security-details-title,
  .profile-membership-details-title,
  .profile-membership-content-container {
    padding: 0;
  }

  .profile-membership-content-container,
  .profile-plus-payment-information {
    display: block;
    margin-top: 10px;
  }

  .profile-form-element-container,
  .account-form-buttons-container,
  .profile-form-element-container-multiple,
  .security-form-buttons-container {
    margin: 10px 0;
  }

  .transaction-table-title-container img {
    margin: 20px 0;
  }

  .guide-article-container {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .privacy-title-container {
    padding: 30px 0;
  }

  .privacy-body-container {
    padding: 0 0 100px;
  }

  .login-form-info-container {
    display: block;
  }

  .login-page-no-account {
    margin-top: 30px;
  }

  .login-page-contact-info {
    margin-top: 20px;
  }

  .register-page-form-container {
    padding-bottom: 100px;
    text-align: center;
  }

  .nav-bar-container {
    height: unset;
  }

  .nav-bar-logo {
    max-width: unset;
    margin-left: 20px;
  }

  .nav-bar-links-container,
  .home-page-landing-image-container {
    display: none;
  }

  .nav-bar-content {
    width: 100%;
  }

  .nav-bar-login-buttons-container {
    margin: 20px;
  }

  .home-page-landing-text-container,
  .home-page-landing-container,
  .home-page-landing-description,
  .home-page-features-title,
  .home-page-features-description,
  .home-single-report-content,
  .homepage-did-you-know-container,
  .home-page-pricing-container,
  .home-page-price-cards-container,
  .home-page-our-story-container,
  .home-page-our-technology-container,
  .home-page-get-started-get-started-banner,
  .home-page-partnerships-container {
    width: calc(100vw - 20px);
  }

  .home-page-landing-title {
    width: 100%;
    font-size: 52px;
    margin-top: 150px;
  }

  .home-page-landing-text-container {
    margin-bottom: 30px;
  }

  .home-single-report-content {
    display: block;
    padding-bottom: 20px;
  }

  .home-single-report-text-block {
    margin: 30px;
    width: unset;
  }

  .home-single-report-card-container {
    margin-top: 40px;
    margin-right: 0;
  }

  .home-single-report-container {
    height: unset;
  }

  .home-single-report-card-text {
    font-size: 14px;
  }

  .homepage-did-you-know-title {
    font-size: 52px;
  }

  .homepage-did-you-know-container {
    margin-left: 10px;
  }

  .homepage-did-you-know-cards-container,
  .home-page-features-cards-container {
    display: block;
    width: 100%;
  }

  .homepage-did-you-know-card-container {
    width: calc(100vw - 20px) !important;
  }

  .homepage-dynamic-banner-container {
    display: none;
  }

  .home-page-faq-container {
    width: calc(100vw - 20px);
  }

  .homepage-dynamic-banner-text-static {
    left: 14px;
  }

  .homepage-dynamic-banner-text-dynamic-container {
    left: 75px;
  }

  .homepage-dynamic-banner-item,
  .homepage-dynamic-banner-text-static {
    font-size: 20px;
  }

  .home-page-price-cards-container .row {
    display: block;
  }

  .home-page-price-card-container.col-sm-6 {
    width: 100%;
  }

  .home-page-our-story-container {
    margin-left: 10px;
    height: fit-content;
  }

  .home-page-our-story-card-container {
    padding-left: 0;
  }

  .home-page-our-story-card {
    padding: 5px 5px 30px;
    border-radius: 0;
    height: fit-content;
  }

  .home-page-our-story-title {
    font-size: 40px;
  }

  .home-page-our-story-content-container {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }

  .home-page-our-story-text-container {
    width: 100%;
  }

  .home-page-our-story-card-backdrop-container {
    background: none !important;
  }

  .home-page-our-story-text-sub-container {
    padding-right: 0;
  }

  .home-page-our-story-image-container {
    width: 90%;
    height: unset;
    margin: auto;
  }

  .home-page-our-story-image-container img {
    width: 100%;
    height: unset;
  }

  .home-page-mentioned-press-logos-container {
    height: unset;
  }

  .home-page-press-logo {
    margin-bottom: 10px;
  }

  .home-page-technology-cards-container {
    width: unset;
    height: unset;
    display: block;
  }

  .home-page-technology-card,
  .home-page-partnerships-card-container {
    width: 100%;
  }

  .home-page-technology-card.with-border {
    border-image: none !important;
  }

  .home-page-get-started-get-started-banner,
  .home-page-partnerships-container,
  .home-page-pricing-container {
    margin-left: 10px;
  }

  .home-page-partnerships-card-container {
    display: block;
  }

  .home-page-partnership-card {
    width: 100%;
    padding: 10px;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .home-page-contact-left-container {
    margin: 0 !important;
    width: 100% !important;
  }

  .home-page-contact-left {
    padding: 10px;
  }

  .home-page-contact-description {
    padding-bottom: 20px;
  }

  .home-page-contact-form-submit-button {
    margin: 10px auto 55px auto !important;
  }

  .footer-socials-container {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .footer-link.link.blog {
    padding-bottom: 30px;
  }

  .home-page-footer-info-container,
  .home-page-footer-container {
    height: unset;
  }

  .nav-bar-login-buttons-container {
    height: 36px;
    margin-left: calc(100vw - 360px);
  }

  .nav-bar-register,
  .nav-bar-login {
    padding: 6px 12px;
  }

  .nav-bar-login {
    border-radius: 5px;
  }

  .home-page-separator {
    width: unset;
  }

  .blog-page-container {
    width: 100vw;
  }

  .blog-description {
    width: 90vw;
    margin: 20px auto;
    text-align: center;
  }

  .blog-heading {
    width: fit-content;
    margin: 0px auto;
  }

  .blog-article-card-container {
    margin: 15px auto;
  }

  .plus-subscription-banner-content {
    display: block;
    width: 100%;
  }

  .plus-subscription-type-content,
  .plus-subscription-features-content {
    width: 100%;
    padding: 10px 5px;
  }

  .mobile-account-provider,
  .mobile-account-number {
    padding-top: 10px;
  }

  .profile-membership-button-container {
    padding: 30px 50px;
  }

  .plus-subscription-banner-container {
    width: 100%;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: unset;
  }

  .plus-subscription-access-container {
    border: none !important;
  }

  .account-dialog-title-center-container,
  .account-dialog-body-container {
    margin: 10px 20px;
    width: calc(100% - 40px);
  }

  .account-dialog-title-center-container .bank-account-provider {
    width: calc(100% - 82px);
    margin-top: 40px;
  }

  .account-dialog-close-container {
    margin: 0;
  }

  .account-dialog-title-center-container .account-dialog-close-container {
    margin-top: 34px;
  }

  .account-dialog-body-section,
  .account-dialog-body {
    width: unset;
  }

  .blog-article-container {
    width: calc(100vw - 20px);
    margin-left: 10px;
  }

  .blog-article {
    width: unset;
  }

  .blog-article-cards-container {
    width: unset;
  }

  .contact-page-title {
    font-size: 28px;
    width: calc(100% - 60px);
    padding: 0;
  }

  .contact-page-back-button {
    height: 30px;
  }

  .individual-contact-page-title-container img {
    margin-right: 0;
    margin-left: -8px;
  }

  .add-contact-title {
    font-size: 24px;
  }
}
