body {
  margin: 0;
  height: 100%;
  overflow: auto;
}

html {
  height: 100%;
}

#root {
  position: relative;
}

code {
  font-family: "Inter", monospace;
}

.home-page-container {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.page-container {
  width: 100vw;
  height: calc(100vh - 80px);
  padding: 0;
  margin: 0;
  display: flex;
  overflow-x: hidden;
}

.outgoings-subpage-container {
  height: 2000px;
}

.privacy-subpage-container,
.profile-subpage-container {
  height: fit-content;
}

.dashboard-subpage-container,
.home-subpage-container,
.contacts-subpage-container,
.documents-subpage-container,
.reports-subpage-container {
  height: 100vh;
}

.reports-subpage-container {
  display: flex;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 100vw !important;
}

.dashboard-subpage-container,
.home-subpage-container,
.outgoings-subpage-container,
.contacts-subpage-container,
.executors-subpage-container,
.privacy-subpage-container,
.documents-subpage-container,
.reports-subpage-container,
.profile-subpage-container,
.knowledge-hub-subpage-container,
.guide-subpage-container {
  margin-left: 300px;
  width: calc(100vw - 246px);
  padding-left: 80px;
  padding-right: 80px;
}

.documents-assets-section-cards-container a:visited,
.documents-assets-section-cards-container a:hover,
.documents-assets-section-cards-container a:link,
.documents-assets-section-cards-container a:active,
.documents-banking-section-card-container a:visited,
.documents-banking-section-card-container a:hover,
.documents-banking-section-card-container a:link,
.documents-banking-section-card-container a:active,
.landing-popup-buttons-container a:visited,
.landing-popup-buttons-container a:hover,
.landing-popup-buttons-container a:link,
.landing-popup-buttons-container a:active,
.link {
  cursor: pointer !important;
  text-decoration: none !important;
  color: unset;
}

.blur {
  position: absolute;
  filter: blur(2px);
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.side-nav-container {
  width: 300px;
  height: calc(100vh - 80px);
  position: fixed;
  z-index: 5;
  overflow-y: auto;
}

.page-container::-webkit-scrollbar,
.side-nav-container::-webkit-scrollbar,
.profile-subpage-container::-webkit-scrollbar {
  display: none;
}

.side-nav-logo-container {
  width: 100%;
  height: 85px;
  padding: 55px;
}

.side-nav-social-links-container,
.side-nav-user-links-container,
.side-nav-other-links-container,
.side-nav-main-links-container {
  margin: 12px 36px 0 36px;
  padding-bottom: 10px;
}

.side-nav-other-links-container {
  height: 170px;
}
.side-nav-main-links-container {
  height: 220px;
}

.side-nav-user-links-container {
  height: auto;
}

.side-nav-main-link.title {
  cursor: default !important;
  opacity: 0.6;
}

.side-nav-main-link {
  width: 189px;
  height: 50px;
  margin-left: 0;
  margin-right: auto;
  font-size: 20px;
  display: block !important;
  border-radius: 15px;
  padding-top: 14px;
  padding-left: 20px;
  text-align: left;
}

.side-nav-sub-link {
  width: 189px;
  height: 50px;
  margin-left: 0;
  margin-right: auto;
  font-size: 16px;
  display: block !important;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 20px;
  text-align: left;
}

.landing-popup-container {
  height: 100%;
  width: 100%;
  padding: 17.5vh 32.5vw;
}

.landing-popup {
  position: absolute;
  top: 15vh;
  left: calc(50% - 300px);
  width: 600px;
  border-radius: 16px;
}

.landing-popup-title {
  padding-top: 131px;
  padding-bottom: 31px;
  font-size: 30px;
  text-align: center;
}

.landing-popup-description {
  padding-top: 33px;
  font-size: 16px;
  text-align: center;
  width: 60%;
  margin: auto;
}

.landing-popup-buttons-container {
  padding-top: 52px;
}

.landing-popup-returning {
  padding-top: 20px !important;
}

.landing-popup-returning,
.landing-popup-new-button {
  border-radius: 6px;
  padding: 12px;
  width: 40%;
  height: 40px;
  text-align: center;
  margin: auto !important;
  border: none;
}

.contact-form-message-container {
  width: 100%;
  padding: 5px;
}

.contact-form-message-container textarea {
  height: 120px;
  width: 100%;
  background: none !important;
  border: none;
  font-size: 21px;
  resize: none;
}

.contact-form-element-container {
  height: 76px;
  padding: 5px;
}

.contact-form-element-container input {
  height: 40px;
  width: 100%;
  background: none !important;
  border: none;
  font-size: 16px;
  padding-bottom: 6px;
  padding-left: 10px;
}

.home-page-contact-form-row-container label {
  font-size: 14px;
  margin-bottom: 6px;
}

.contact-form-message-container,
.contact-form-element-container {
  height: 100%;
  border-radius: 15px;
}

.home-page-contact-form-row-container label {
  padding: 10px 0;
}

.register-password-message {
  padding-top: 6px;
  font-size: 11px !important;
}

.user {
  position: absolute;
  top: 20px;
  right: 30px;
}

.logout-button {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 50px;
  padding: 10px;
  border-radius: 5px;
}

.bud-banner-container {
  width: 100%;
  height: 100%;
  padding-top: 5%;
  padding-left: -2%;
  overflow: hidden;
}

.bud-banner-container img {
  width: 95%;
  max-width: 1100px;
}

.bank-accounts-container {
  width: 100%;
  max-width: 1200px;
  height: 270px;
  border-radius: 50px;
}

.bank-accounts-top-container {
  display: flex;
  width: 100%;
  height: 86px;
  position: relative;
}

.bank-accounts-title {
  font-size: 24px;
  position: absolute;
  top: 43px;
  left: 57px;
}

.bank-accounts-refresh-button,
.bank-accounts-add-button {
  border-radius: 10px;
  background: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bank-accounts-add-button {
  width: 190px;
  position: absolute;
  top: 37px;
  right: 50px;
  height: 45px;
}

.bank-accounts-refresh-button {
  width: 235px;
  position: absolute;
  top: 37px;
  right: 250px;
  height: 45px;
}

.bank-accounts-refresh-button img,
.bank-accounts-add-button img {
  width: 21px !important;
  height: 21px !important;
  position: absolute;
  top: 11px;
  left: 20px;
}

.bank-accounts-refresh-button-text,
.bank-accounts-add-button-text {
  font-size: 20px;
  position: absolute;
  top: 8px;
  left: 50px;
}

.bank-accounts-cards-container {
  height: 119px;
  margin: 36px 57px 41px 57px;
}

.bank-accounts-bottom-container {
  position: relative;
}

.accounts-next-arrow,
.accounts-previous-arrow {
  border-radius: 38px;
  width: 52px;
  height: 52px;
  display: block !important;
  position: absolute;
  top: 30px;
  border: none;
  z-index: 2;
}

.accounts-next-arrow img,
.accounts-previous-arrow img {
  width: 32px !important;
  height: 32px !important;
  margin: auto;
}

.accounts-next-arrow {
  right: 15px;
}

.accounts-previous-arrow {
  left: 15px;
  transform: rotate(180deg);
}

.bank-account-card {
  width: 202px;
  height: 119px;
  border-radius: 14px;
}

.bank-account-card-top-container {
  height: 51px;
  width: 100%;
  display: flex;
}

.bank-account-chip {
  border-radius: 5px;
  width: 35px;
  height: 21px;
  margin: 21px 85px 0 21px;
}

.bank-account-chip img {
  border-radius: 5px;
  height: 93%;
}

.bank-account-logo img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.bank-account-provider-card {
  margin: 8px 20px 0 20px;
  font-size: 19px;
}

.bank-account-id-card {
  margin: 5px 20px 0 20px;
  font-size: 19px;
}

.bank-accounts-container {
  margin-top: 66px;
  margin-bottom: 66px;
}

.transaction-table-element-container {
  width: 100%;
  max-width: 1200px;
  border-radius: 50px;
  padding-bottom: 20px;
}

.transaction-table-title-container {
  height: 142px;
  width: 100%;
  display: flex;
  align-items: center;
}

.transaction-table-title-container img {
  margin: 58px 72px 0 auto;
  width: 30px;
  height: 30px;
}

.transaction-table-title {
  /* padding-top: 60px; */
  /* margin: 0 0 43px 64px; */
  padding-left: 43px;
  font-size: 26px;
  text-align: left;
  /* vert-align: middle; */
}

.executors-table-container {
  min-height: 60%;
}

.executors-table-container,
.transaction-table-container {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 45px;
  border-radius: 15px;
}

.table-headers-container {
  display: flex;
  width: 100% !important;
  font-size: 19px;
  border-radius: 15px 15px 0 0;
}

.table-header-cell-container {
  padding-top: 18px;
  padding-bottom: 24px;
}

.table-header-cell-container.name {
  width: 34%;
  border-top-left-radius: 15px;
  padding-left: 63px;
}

.table-body-cell-name {
  margin-left: 17px;
  margin-right: 5px;
}

.table-body-cell-full-name {
  margin-left: 40px;
}

.table-body-cell.name {
  width: 34%;
  padding-left: 23px;
}

.table-body-cell.full-name {
  width: 50%;
  padding-left: 23px;
}

.table-header-cell-container.full-name {
  width: 50%;
  border-top-left-radius: 15px;
  padding-left: 63px;
}

.table-header-cell-container.bank {
  width: 15%;
}

.table-header-cell-container.date {
  width: 25%;
}

.table-header-cell-container.permissions {
  width: 38%;
}

.table-body-cell.bank {
  width: 15%;
}

.table-body-cell.date {
  width: 25%;
}

.table-body-cell.permissions {
  width: 38%;
}

.table-body-cell.edit {
  width: 10%;
}

.table-body-cell-edit {
  border-bottom: 1px solid #f7f7f7;
}

.table-header-cell-container.amount {
  width: 24%;
  border-top-right-radius: 15px;
}

.table-body-cell.amount {
  width: 24%;
}

.table-body-cell.more {
  /* width: 1%; */
  width: 34px;
  height: 38px;
  position: relative;
  border-radius: 5px;
  /* margin-right: 1%; */
  box-shadow: 0 3px 2px rgba(255, 255, 255, 0.2), /* Light shadow for subtle depth */ 0 6px 5px rgba(255, 255, 255, 0.1); /* Soft outer glow */
  border: none; /* Remove default border */
  transition: box-shadow 0.3s; /* Smooth transition for hover effect */
  cursor: pointer;
}

.table-body-cell.more:hover,
.clear-button:hover {
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.3), /* Slightly deeper shadow on hover */ 0 2px 8px
    rgba(255, 255, 255, 0.15); /* Slight enhancement on outer glow */
}

.clear-button {
  margin-left: 1rem;
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
}

.clear-button > i {
  color: white;
}

.table-body-cell.more:after {
  content: "";
  position: absolute;
  left: 50%;
  /* top: 36%; */
  top: 50%;
  width: 1.5px;
  height: 1.5px;
  margin-left: -1px;
  margin-top: -1px;
  border-radius: 50%;
}

.table-body-cell-amount,
.table-body-cell-date,
.table-body-cell-name {
  margin-top: 5px;
}

.table-row-container {
  margin: 0 0 30px 0;
}

.table-body-cell,
.table-row-container {
  display: flex;
  font-size: 19px;
}

.table-body-cell img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
}

.transaction-table-total-amount-container {
  height: 90px;
  display: flex;
  margin: 0 55px 0 55px;
  width: calc(100% - 110px);
  align-items: flex-start;
}

.transaction-table-total-amount-label {
  font-size: 16px;
  margin-right: 5px;
  width: calc(100% - 5px);
  text-align: right;
}

.transaction-table-something-missing-container {
  height: 90px;
  display: flex;
  margin: 0 55px 0 55px;
  width: calc(100% - 110px);
  align-items: flex-start;
}

.transaction-table-something-missing-add,
.transaction-table-something-missing-label {
  font-size: 16px;
  margin-right: 5px;
}

.transaction-table-something-missing-add {
  padding-bottom: 1px;
  width: 100px;
  cursor: pointer;
}

.transaction-table-something-missing-label {
  width: calc(100% - 100px);
  text-align: right;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  margin-left: -2px;
  font-size: 20px !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px !important;
  border-radius: 50px;
  opacity: 1 !important;
  z-index: 4;
}

.swiper-button-disabled {
  display: none !important;
  ;
}

.add-transaction-form-container {
  position: absolute;
  top: calc((100vh - 850px) / 2);
  left: calc((100vw - 847px) / 2);
  width: 847px;
  height: 850px;
  padding: 0 !important;
  border-radius: 10px;
  z-index: 100;
}

.add-transaction-form-top-container {
  width: calc(100% - 114px);
  margin: 0 57px 0 57px;
  height: 167px;
}

.add-transaction-form-top {
  display: flex;
}

.add-transaction-form-heading {
  padding: 43px 0 0 0;
  font-size: 26px;
  width: 560px;
}

.add-transaction-form-close {
  padding: 46px 0 0 0;
  margin-left: 140px;
  background: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

.add-transaction-form-close img {
  width: 100%;
}

.add-transaction-form-description {
  font-size: 20px;
  margin: 22px 0 22px 0;
}

.add-transaction-form-row-container {
  height: 80px;
}

.add-transaction-form-row-container-large,
.add-transaction-form-row-container {
  width: calc(100% - 114px) !important;
  margin: 0 57px 0 57px;
  display: flex;
}

.add-transaction-form-row-container-large {
  height: 170px;
}

.add-transaction-form-row-label {
  width: calc(40% - 10px);
  margin: 22px 0 28px 10px;
}

.add-transaction-form-row-label-large {
  width: calc(40% - 10px);
  margin: 22px 0 97px 10px;
}

.add-transaction-form-row-input-large,
.add-transaction-form-row-input {
  width: calc(60% - 120px);
  margin: 21px 10px 20px 110px;
  position: relative;
  display: flex;
}

.add-transaction-form-row-input-large,
.add-transaction-form-row-label,
.add-transaction-form-row-label-large,
.add-transaction-form-row-input {
  font-size: 24px;
  text-align: left;
}

.add-transaction-form-add-button-container {
  width: calc(100% - 127px) !important;
  margin: 20px 70px 0 57px;
  height: 69px;
}

.add-transaction-form-add-button {
  width: 249px;
  height: 49px;
  margin-left: calc(100% - 249px);
  border: none;
  border-radius: 12px;
  font-size: 17px;
}

.add-transaction-form-dropdown {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 20px;
  padding: 7px 5px 7px 5px;
  text-align: left;
  overflow-x: hidden;
}

.add-transaction-form-dropdown-items {
  font-size: 20px;
  text-align: center;
  padding: 15px 0 15px 0;
}

.add-transaction-form-row-input img {
  position: sticky;
  width: 23px !important;
  height: 23px !important;
  margin-left: -30px;
  margin-top: 7px;
  pointer-events: none;
}

.add-transaction-form-row-input-large textarea,
.add-transaction-form-row-input input {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 20px;
  padding: 7px 5px 7px 5px;
  text-align: left;
  overflow-x: hidden;
}

.add-transaction-form-row-input-large textarea {
  height: 126px;
  vertical-align: top;
  resize: none;
}

.add-contact-form-input select,
.add-transaction-form-row-input select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.account-dialog-component {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 473px;
  height: 100vh;
  padding: 0 !important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.account-dialog-title-container {
  height: 104px;
}

.account-dialog-title-center-container {
  display: flex;
  width: calc(100% - 102px);
  margin-left: 51px;
  margin-right: 51px;
  border-bottom: 1px solid #585858;
}

.account-dialog-title-center-container .bank-account-logo {
  margin-top: 34px;
  min-width: 44px;
}

.bank-account-logo {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  margin: 12px 18px 13px -1px;
}

.bank-account-provider {
  margin-top: 40px;
  font-size: 30px;
}

.account-dialog-close-container {
  margin: 40px 2px 20px 152px;
}

.account-dialog-close {
  background: inherit;
  border: none;
  outline: inherit;
  cursor: pointer;
  width: 38px;
  height: 38px;
}

.account-dialog-close img {
  width: 100%;
}

.account-dialog-body-container {
  width: calc(100% - 51px);
  height: 170px;
  margin: 11px 48px 0 51px;
  padding: 0 !important;
}

.account-dialog-body-section,
.account-dialog-body {
  display: flex;
  width: calc(100% - 102px);
  margin-top: 16px;
}

.account-dialog-body-section {
  margin-top: 32px !important;
}

.account-dialog-value,
.account-dialog-label {
  font-size: 20px;
}

.account-dialog-label {
  margin-right: 10%;
  width: 55%;
}

.account-dialog-value {
  text-align: right;
  width: 35%;
}

.account-dialog-buttons-container {
  display: block;
  margin-top: 68px;
}

.account-dialog-buttons-container button {
  width: calc(100% - 102px);
  height: 49px;
  margin-left: 51px;
  margin-right: 51px;
}

.account-dialog-delete-button {
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
  border: none;
}

.delete-document-dialogue,
.delete-contact-dialogue,
.delete-account-dialogue {
  position: absolute;
  top: calc((100vh - 254px) / 2);
  left: calc((100vw - 400px) / 2);
  z-index: 10;
  width: 400px;
  height: 254px;
  padding: 0 !important;
  border-radius: 10px;
}

.delete-document-dialogue-close-container,
.delete-contact-dialogue-close-container,
.delete-account-dialogue-close-container {
  width: 100% !important;
  height: 60px !important;
}

.delete-document-dialogue-close,
.delete-contact-dialogue-close,
.delete-account-dialogue-close {
  background: inherit;
  border: none;
  margin: 32px 0 24px calc(100% - 57px);
}

.delete-document-dialogue-close img,
.delete-contact-dialogue-close img,
.delete-account-dialogue-close img {
  width: 28px !important;
  height: 28px !important;
  cursor: pointer;
}

.delete-document-dialogue-title-container,
.delete-contact-dialogue-title-container,
.delete-account-dialogue-title-container {
  align-items: center;
}

.delete-document-dialogue-title-container img,
.delete-contact-dialogue-title-container img,
.delete-account-dialogue-title-container img {
  margin: 24px 0 8px 24px;
  width: 38px !important;
  height: 38px !important;
}

.delete-document-dialogue-title,
.delete-contact-dialogue-title,
.delete-account-dialogue-title {
  font-size: 18px;
  text-align: left;
  width: 80%;
  margin-left: 24px;
  margin-right: 24px;
}

.delete-document-dialogue-body,
.delete-contact-dialogue-body,
.delete-account-dialogue-body {
  text-align: left;
  width: 80%;
  margin-left: 24px;
  margin-right: 24px;
  font-size: 14px;
  margin-top: 8px;
}

.delete-account-dialogue-link {
  text-align: center;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  font-size: 18px;
  margin-top: 15px;
  cursor: pointer;
}

.delete-document-dialogue-buttons-container,
.delete-contact-dialogue-buttons-container,
.delete-account-dialogue-buttons-container {
  margin: 24px;
  width: calc(100% - 48px);
  height: 49px;
  display: flex;
}

.delete-document-dialogue-cancel-button,
.delete-document-dialogue-hide-button,
.delete-contact-dialogue-cancel-button,
.delete-contact-dialogue-hide-button,
.delete-account-dialogue-cancel-button,
.delete-account-dialogue-hide-button {
  width: 203px;
  height: 49px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.delete-document-dialogue-cancel-button,
.delete-contact-dialogue-cancel-button,
.delete-account-dialogue-cancel-button {
  background: none;
  margin-right: 10px;
}

.delete-document-dialogue-hide-button,
.delete-contact-dialogue-hide-button,
.delete-account-dialogue-hide-button {
  border: none;
}

.transaction-table-dialogue {
  position: absolute;
  width: 174px;
  height: 104px;
  padding: 0 !important;
  border-radius: 10px;
}

.transaction-table-dialogue-buttons {
  height: 80%;
}

.transaction-table-dialogue-buttons button {
  height: 60%;
  width: 100%;
  font-size: 20px;
  text-align: center;
  background: none;
  border: none;
  outline: inherit;
  cursor: pointer;
}

.transaction-details-dialogue {
  position: absolute;
  top: calc((100vh - 513px) / 2);
  left: calc((100vw - 847px) / 2);
  z-index: 100;
  width: 847px;
  /*height: 513px;*/
  padding: 0 !important;
  border-radius: 10px;
}

.transaction-details-dialogue-top-container {
  width: calc(100% - 94px) !important;
  margin: 0 47px 0 47px;
  border-bottom: 1px solid #585858;
  ;
  height: 106px !important;
  display: flex;
}

.transaction-details-dialogue-heading {
  margin-left: 10px;
  padding: 43px 0 29px 0;
  font-size: 26px;
  width: 560px;
}

.transaction-details-dialogue-close {
  padding: 40px 0 0 0;
  margin-left: 140px;
  background: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
}

.transaction-details-dialogue-close img {
  width: 100%;
}

.transaction-details-dialogue-row-container {
  height: 91px;
  width: calc(100% - 94px) !important;
  margin: 0 47px 0 47px;
  display: flex;
}

.transaction-details-dialogue-row-label {
  text-align: left;
  margin-left: 20px !important;
  width: calc(60% - 20px);
}

.transaction-details-dialogue-row-value {
  text-align: right;
  margin-right: 20px !important;
  width: calc(40% - 20px);
}

.transaction-details-dialogue-row-value,
.transaction-details-dialogue-row-label {
  font-size: 24px;
  margin: 36px 0 26px 0;
}

.hide-transaction-dialogue {
  position: absolute;
  top: calc((100vh - 424px) / 2);
  left: calc((100vw - 482px) / 2);
  z-index: 10;
  width: 482px;
  height: 424px;
  padding: 0 !important;
  border-radius: 10px;
}

.hide-transaction-dialogue-close-container {
  width: 100% !important;
  height: 60px !important;
}

.hide-transaction-dialogue-title-container img {
  margin: 32px 0 27px 32px;
  width: 38px !important;
  height: 38px !important;
}

.hide-transaction-dialogue-title {
  font-size: 18px;
  text-align: left;
  width: 80%;
  margin-left: 32px;
  margin-right: 32px;
}

.hide-transaction-dialogue-body {
  text-align: left;
  width: 80%;
  margin-left: 32px;
  margin-right: 32px;
  font-size: 16px;
  margin-top: 20px;
}

.hide-transaction-dialogue-link {
  text-align: left;
  width: 80%;
  margin-left: 32px;
  margin-right: 32px;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
}

.hide-transaction-dialogue-buttons-container {
  margin: 25px 24px 0 24px;
  width: calc(100% - 48px);
  height: 49px;
  display: flex;
}

.hide-transaction-dialogue-cancel-button,
.hide-transaction-dialogue-hide-button {
  width: 203px;
  height: 49px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  padding-top: 8px;
}

.hide-transaction-dialogue-cancel-button {
  background: none;
  margin-right: 28px;
}

.hide-transaction-dialogue-hide-button {
  border: none;
}

.contacts-page-title-container {
  display: flex;
}

.contact-page-container {
  margin-top: 173px;
}

.contact-page-title {
  font-size: 45px;
  width: 200px;
}

.contact-page-add-button {
  padding-top: 15px;
  display: flex;
  border-radius: 5px;
  width: 177px;
  font-size: 16px;
  margin-left: calc(75vw - 500px);
  border: none;
  justify-content: center;
}

.contact-page-add-button {
  font-size: 20px;
}

.contact-page-add-button img {
  width: 21px !important;
  height: 21px !important;
  margin-right: 10px;
  padding-top: 3px;
}

.contacts-page-contacts-container {
  margin-top: 47px;
  font-size: 20px;
}

.contact-page-back-button {
  transform: rotate(180deg);
  height: 50px;
}

.individual-contact-page-title-container {
  display: flex;
}

.individual-contact-page-title-container img {
  margin-right: 20px;
}

.individual-contact-page-container {
  margin-top: 71px;
}

.add-contact-component-container {
  margin-top: 30px;
}

.add-document-container,
.add-contact-container {
  min-width: 787px;
  max-width: 1200px;
  border-radius: 40px;
}

.add-document-container {
  height: 740px;
}

.add-contact-container {
  height: 800px;
}

.add-document-form-description-container,
.add-document-form-element-container,
.add-contact-form-element-container {
  height: 76px;
  padding: 5px;
  border-radius: 10px;
  margin: 10px 75px 0 40px;
}

.add-document-buttons-container,
.add-contact-buttons-container {
  margin: 0 75px 0 40px;
  display: flex;
}

.add-document-form-element-container-multiple,
.add-contact-form-element-container-multiple {
  height: 76px;
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 8.33333333%;
  margin-left: 0 !important;
}
.add-document-form-element-container-multiple input,
.add-document-form-element-container input,
.add-contact-form-element-container-multiple input,
.add-contact-form-element-container input,
.add-document-form-description-container textarea {
  height: 34px;
  width: calc(100% - 19px);
  background: none !important;
  border: none;
  font-size: 21px;
}

.add-document-form-description-container textarea {
  height: 150px !important;
  resize: none;
}

.add-document-form-element-container-multiple label,
.add-contact-form-element-container-multiple label {
  font-size: 14px;
}

.add-document-form-element-container input,
.add-document-form-element-container label,
.add-document-form-element-container-multiple input,
.add-document-form-element-container-multiple label,
.add-contact-form-element-container input,
.add-contact-form-element-container label,
.add-document-form-description-container textarea,
.add-document-form-description-container label,
.add-contact-form-element-container-multiple input,
.add-contact-form-element-container-multiple label {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6px;
}

.profile-form-row-container,
.add-document-form-row-container,
.add-contact-form-row-container {
  display: flex;
  margin: 0 40px 0 40px;
  flex-wrap: wrap;
}

.add-contact-form-input {
  position: relative;
}

.add-contact-form-dropdown {
  width: 100%;
  height: 40px;
  background: inherit;
  border: none;
  font-size: 20px;
  padding: 7px 5px 7px 5px;
  text-align: left;
  overflow-x: hidden;
}

.add-contact-form-dropdown-items {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  padding: 15px 0 15px 0;
}

.add-contact-form-input img {
  position: sticky;
  width: 20px !important;
  height: 20px !important;
  margin-left: -30px;
  margin-top: -15px;
  border-radius: 50px;
  pointer-events: none;
  padding: 5px;
}

.profile-membership-details-title,
.account-details-title,
.security-details-title {
  width: 100%;
  padding: 47px 107px 30px 49px;
  font-size: 24px;
}

.add-document-title,
.add-contact-title {
  width: 100%;
  padding: 47px 107px 25px 49px;
  font-size: 30px;
}

.add-contact-permissions-title {
  padding: 30px 107px 10px 39px;
}

.add-contact-have-access-later-container,
.add-contact-have-access-now-container {
  width: 100%;
  padding: 10px 107px 10px 39px;
  display: flex;
}

.add-contact-have-account-message {
  width: 100%;
  padding: 5px 107px 5px 39px;
  font-size: 16px;
}

.add-contact-have-access-later-container input,
.add-contact-have-access-now-container input {
  width: 33px;
}

.add-contact-have-access-later-text,
.add-contact-have-access-now-text {
  font-size: 18px;
  margin-left: 28px;
}

.add-document-button,
.add-contact-button {
  border: none;
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  height: 40px;
  text-align: center;
  margin: auto !important;
  border: none;
}

.add-contact-submit-button-container,
.add-document-submit-button-container {
  padding-top: 30px;
  width: 50%;
}

.add-contact-delete-button,
.add-document-delete-button,
.add-document-cancel-button {
  margin-top: 40px;
  margin-left: 40px;
  width: 30%;
}

.contacts-page-contacts-grid {
  display: flex;
  width: 100%;
}

.contact-card {
  margin: 5px 18px 10px;
  border-radius: 24px;
  height: 200px;
  min-width: 350px;
}

.contact-profile-picture-container {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  margin-right: 23px;
}

.contact-card-name {
  margin-top: 10px;
  width: available;
}

.contact-profile-picture-letters {
  text-align: center;
  font-size: 15px;
  padding-top: 12px;
}

.contact-card-top-container {
  display: flex;
  height: 84px;
  padding: 23px 33px 13px;
}

.contact-card-bottom-container {
  margin-top: 17px;
  margin-left: 38px;
  margin-right: 30px;
  overflow: hidden;
}

.contact-card-relationship,
.contact-card-email,
.contact-card-phone-number {
  font-size: 18px;
  height: 23px;
}

.documents-assets-section-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(153px, 1fr));
  gap: 0.4rem;
  margin: 1rem 0;
  /*display: flex;*/
  /*max-width: 1200px;*/
}

/*.documents-assets-section-cards-container .dashboard-col {*/
/*  max-width: 200px;*/
/*}*/

.documents-section-card {
  border-radius: 12px;
  /*width: 162px;*/
  width: 100%;
  height: 178px;
  /*margin-right: 27px;*/
  /*margin-top: 37px;*/
  padding-top: 1px;
  background-size: 74px, cover;
  background-repeat: no-repeat;
  background-position: center 30%;
}

.documents-banking-card .documents-section-card {
  margin-top: 0 !important;
}

.documents-section-card-image-container {
  width: 84px;
  height: 84px;
  margin: 29px auto 0;
  border-radius: 15px;
}

.documents-section-card-image {
  margin: 5px;
  width: 74px;
  height: 74px;
  object-fit: contain;
}

.documents-section-card-title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  position: absolute;
  bottom: 2rem;
  padding: 0 5px;
}

.documents-banking-section-container,
.documents-insurance-section-container,
.documents-other-section-container,
.documents-future-section-container,
.documents-assets-section-container {
  margin-top: 55px;
}

.documents-section-label {
  font-size: 24px;
}

.documents-section-container {
  margin-top: 148px;
  display: flex;
}

.documents-section-image-container {
  width: 130px;
  height: 130px;
  border-radius: 23px;
  margin-right: 48px;
}

.documents-section-image-container img {
  width: 116px;
  height: 116px;
  margin: 7px;
}

.documents-section-info-container {
  width: calc(100% - (130px + 200px + 250px));
  margin-right: 75px;
}

.documents-section-info-title {
  font-size: 35px;
  margin-bottom: 24px;
}

.documents-section-info-description {
  font-size: 20px;
}

.documents-add-button {
  min-width: 200px;
  max-width: 280px;
  height: 50px;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  font-size: 20px;
}

.documents-page-breadcrumb-container {
  margin-top: 20px;
}

.documents-page-breadcrumb a,
.documents-page-breadcrumb a:hover,
.documents-page-breadcrumb a:visited,
.documents-page-breadcrumb a:active,
.documents-page-breadcrumb a:link {
  text-decoration: none;
}

.documents-add-button-text-plus {
  margin-right: 10px;
}

.current-documents-display-container {
  margin-top: 82px;
  margin-right: 10%;
  width: 90%;
}

.document-card {
  border-radius: 18px;
  height: 159px;
  display: flex;
  padding: 27px 21px 27px 25px;
}

/*.document-card.link.file-preview {*/
/*  height: 500px;*/
/*}*/

.document-card-image-container {
  width: 30%;
  padding: 5px;
}

.document-card-text-container {
  width: 70%;
}

.document-card-name {
  height: 20px;
  overflow: hidden;
  font-size: 17px;
}

.document-card-info {
  height: 20px;
  overflow: hidden;
  font-size: 16px;
}

.download-file-button {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  border-radius: 50px;
  width: 42px;
  height: 42px;
}

.add-document-form-upload-container {
  position: relative;
  display: flex;
  height: 76px;
  border-radius: 10px;
  margin: 10px 75px 0 40px;
}

.add-document-form-description-container {
  position: relative;
  height: 175px;
  border-radius: 10px;
  margin: 10px 75px 0 40px;
}

.current-file-image-container {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-left: 23px;
}

.current-file-image-container img {
  margin: 4px 5px 5px 6px;
  width: 30px;
  height: 30px;
}

.upload-file-input-button {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.current-file-name {
  margin-top: 15px;
  margin-left: 15px;
}

.reports-subpage-navigation-container {
  width: 650px;
  padding-left: 295px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 0;
}

.reports-subpage-navigation-title {
  margin-top: 206px;
  margin-left: 75px;
  font-size: 24px;
}

.no-reports-message {
  margin-left: 57px;
  margin-top: 29px;
  width: 180px;
  font-size: 16px;
}

.reports-subpage-preview-container {
  margin-left: 349px;
  width: 100%;
  height: 100%;
  padding-top: 80px;
}

.report-viewer {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  height: calc(100% - 5px);
}

.profile-membership-container,
.profile-form-container,
.security-form-container {
  width: 100%;
  max-width: 1200px;
  border-radius: 40px;
  margin-top: 27px;
}

.profile-form-element-container {
  height: 76px;
  padding: 5px;
  border-radius: 10px;
  margin: 10px 50px 0 40px;
  min-width: 250px;
}

.profile-form-element-container-multiple {
  height: 76px;
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0 !important;
  min-width: 250px;
}
.profile-form-element-container-multiple input,
.profile-form-element-container input {
  height: 34px;
  width: calc(100% - 19px);
  background: none !important;
  border: none;
  font-size: 21px;
}

.profile-form-element-container-multiple label,
.profile-form-element-container label {
  font-size: 14px;
}

.profile-form-element-container-multiple label,
.profile-form-element-container label,
.profile-form-element-container-multiple input,
.profile-form-element-container input {
  margin-left: 10px;
  margin-right: 10px;
}

.account-form-buttons-container,
.security-form-buttons-container {
  margin: 20px 85px 34px calc(100% - 340px - 50px);
  padding-bottom: 34px;
  width: 340px;
  display: flex;
}

.account-cancel-changes {
  padding-top: 10px;
  margin-right: 50px;
  font-size: 16px;
}

.profile-page-title {
  margin-top: 45px;
  font-size: 35px;
}

.table-body-cell.refresh {
  min-width: 50px;
}

.table-body-cell.refresh img {
  width: 20px !important;
  height: 20px !important;
}

.nav-bar-container {
  width: 100vw;
  height: 76px;
  position: fixed;
  z-index: 99;
}

.nav-bar-content {
  width: 1200px;
  display: flex;
  margin: auto;
}

.nav-bar-logo {
  max-width: 100px;
}

.nav-bar-image-container {
  width: 153px;
  height: 100%;
}

.nav-bar-image-container img {
  margin-top: 25px;
  width: 153px;
}

.nav-bar-links-container {
  display: flex;
  height: 100%;
  margin-top: 30px;
  margin-left: 100px;
}

.nav-bar-link {
  text-decoration: none;
  text-align: center;
  width: fit-content;
  margin: 0 30px 0 30px;
}

.home-page-content {
  width: 1200px;
  margin: auto;
}

.home-page-landing-container {
  width: 1200px;
  margin: auto;
  display: flex;
}

.home-page-landing-text-container {
  width: 60%;
  margin-bottom: 200px;
}

.home-page-landing-image-container {
  width: 40%;
  height: 600px;
  position: relative;
}

.home-page-landing-image-container img {
  position: absolute;
  top: -30px;
  left: -150px;
}

.home-page-landing-title {
  margin-top: 280px;
  font-size: 70px;
  font-weight: 500;
  width: 600px;
}

.home-page-landing-description {
  margin-top: 30px;
  font-size: 18px;
  width: 512px;
}

.home-page-features-container {
  margin-top: 30px;
}

.home-page-features-title {
  font-size: 52px;
  text-align: center;
  width: 650px;
  margin: auto;
}

.home-page-features-description {
  font-size: 18px;
  text-align: center;
  width: 670px;
  margin: 36px auto;
}

.homepage-did-you-know-cards-container,
.home-page-features-cards-container {
  width: 1012px;
  margin: auto;
  padding-bottom: 115px;
  position: relative;
  display: flex;
}

.homepage-did-you-know-card-container {
  z-index: 1;
  width: 335px !important;
  margin: 5px;
}

.homepage-did-you-know-background {
  filter: blur(35px);
  position: absolute;
  width: 33%;
  height: 80%;
  margin-left: 33%;
}

.feature-card-container {
  padding: 5px;
}

.feature-card {
  border-radius: 15px;
  padding: 29px;
  display: flex;
  width: 100%;
}

.feature-card-image-container img {
  width: 20px;
}

.feature-card-text-container {
  padding-left: 10px;
  padding-top: 3px;
  font-size: 16px;
}

.homepage-did-you-know-card {
  text-align: center;
  padding: 5px 45px;
}

.homepage-did-you-know-title {
  font-size: 60px;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
}

.homepage-did-you-know-card-title {
  font-size: 70px;
  padding-top: 30px;
  margin-bottom: 16px;
}

.homepage-did-you-know-card-description {
  font-size: 16px;
  padding-bottom: 20px;
}

.home-page-pricing-title {
  text-align: center;
  font-size: 60px;
}

.home-page-pricing-description {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
}

.home-page-price-cards-container {
  padding-top: 40px;
  width: 1100px;
  margin: auto;
}

.home-page-price-card-container {
  padding: 23px;
}

.home-page-price-card {
  padding: 31px 53px 65px 53px;
  border-radius: 12px;
  height: 726px;
  position: relative;
  border: solid 2px transparent;
}

.home-page-price-card-type {
  font-size: 18px;
}

.home-page-price-card-cost {
  margin-top: 14px;
  font-size: 60px;
  display: flex;
  margin-left: -4px;
}

.home-page-price-card-period {
  margin-top: 30px;
  margin-left: 10px;
  font-size: 16px;
}

.home-page-price-card-short-desc {
  font-size: 16px;
  margin-top: 18px;
}

.home-page-price-card-info {
  padding-bottom: 55px;
}

.home-page-price-card-features {
  padding-top: 30px;
  padding-bottom: 30px;
}

.home-page-price-card-feature {
  display: flex;
  margin-bottom: 20px;
}

.home-page-price-card-feature img {
  height: 24px;
}

.home-page-price-card-feature-text {
  padding-left: 10px;
  padding-top: 5px;
  font-size: 16px;
}

.home-page-price-card-button-container {
  position: absolute;
  width: 100%;
  height: 40px;
  bottom: 23px;
  left: 0;
  text-align: center;
}

.home-page-price-card-button {
  height: 40px;
  width: 200px;
  border-radius: 10px;
  padding: 0 15px;
}

.home-page-pricing-container {
  padding-bottom: 90px;
}

.home-page-price-card-container.col-sm-6 {
  width: 48%;
  margin: auto;
}

.home-page-our-story-content-container {
  padding-top: 60px;
  margin: auto;
  display: flex;
}

.home-page-our-story-title {
  font-size: 60px;
  text-align: center;
}

.home-page-our-story-image {
  width: 453px;
  height: 469px;
  margin-top: 30px;
  aspect-ratio: 0.97;
}

.home-page-our-story-text-container {
  width: 64%;
}

.home-page-our-story-image-container {
  width: 40%;
  height: 470px;
}

.home-page-our-story-text-sub-container {
  margin-top: 20px;
  padding-right: 20%;
}

.home-page-our-story-text {
  font-size: 22px;
  margin-top: 17px;
}

.home-page-mentioned-press {
  text-align: center;
  font-size: 20px;
  padding-bottom: 5px;
}

.home-page-mentioned-press-logos-container {
  padding-top: 24px;
  width: 60%;
  margin: auto;
  height: 60px;
}

.home-page-press-logo img {
  height: 28px;
  object-fit: scale-down;
  margin: auto;
  width: 90%;
}

.home-page-mentioned-press-contact {
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
}

.home-page-mentioned-press-container {
  padding-top: 100px;
  padding-bottom: 80px;
}

.home-page-container.modal-open {
  overflow-y: hidden;
}

.nav-bar-login-buttons-container {
  display: flex;
  height: 44px;
  margin: 18px 0 0 auto;
}

.nav-bar-register,
.nav-bar-login {
  font-size: 16px;
  padding: 12px 25px;
  border-radius: 15px;
}

.home-page-partnerships-container {
  width: 100%;
  margin: 112px auto 0 auto;
}

.home-page-partnerships-title {
  font-size: 60px;
  text-align: center;
}

.home-page-partnerships-description {
  margin-top: 20px;
  text-align: center;
}

.home-page-partnerships-card-container {
  border-radius: 10px;
  display: flex;
  padding: 22px;
  width: 1200px;
  margin: 80px auto;
}

.home-page-partnership-card {
  width: calc(100% / 3);
  height: 400px;
  padding: 80px 50px;
}

.home-page-partnership-card.one {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.home-page-partnership-card.three {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.home-page-partnership-card-title {
  font-size: 40px;
  padding-bottom: 34px;
  text-align: center;
}

.home-page-partnership-card-description {
  font-size: 18px;
  text-align: center;
}

.partnerships-get-in-touch-button-container {
  margin-top: 100px;
  text-align: center;
  padding-bottom: 100px;
}

.partnerships-get-in-touch-button {
  margin: auto;
  width: 200px;
  font-size: 16px;
  padding: 12px 25px;
  border-radius: 12px;
}

.home-page-our-technology-container {
  padding-bottom: 70px;
}

.home-page-our-technology-title {
  font-size: 60px;
  text-align: center;
}

.home-page-our-technology-description {
  font-size: 16px;
  text-align: center;
  max-width: 680px;
  margin: 20px auto;
  line-height: 26px;
}

.home-page-technology-cards-container {
  height: 300px;
  width: 1200px;
  margin: 70px auto;
  position: relative;
  display: flex;
}

.home-page-technology-card {
  width: 33%;
}

.home-page-technology-card.with-border {
  border-width: 0;
  border-right: solid 2px;
}

.home-page-technology-card-image {
  width: 70px;
  margin: auto;
  padding-top: 10px;
}

.home-page-technology-card-title {
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  padding-top: 30px;
}

.home-page-technology-card-text {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding: 30px;
  line-height: 26px;
}

.rainbow-glow-container {
  position: absolute;
  filter: blur(15px);
  width: 90%;
  height: 91%;
  margin-top: 6%;
  margin-left: 5%;
}

.home-page-get-started-get-started-banner {
  width: 100%;
  height: 374px;
}

.get-started-banner-title {
  text-align: center;
  font-size: 36px;
  padding-top: 96px;
}

.get-started-banner-button {
  text-align: center;
  width: fit-content;
  border-radius: 7px;
  margin: 40px auto;
  padding: 12px 20px;
}

.get-started-banner-description {
  text-align: center;
  font-size: 15px;
  padding-top: 20px;
}

.home-page-faq-container {
  width: 1200px;
  padding: 30px 0 200px 0;
  margin: 0 auto;
}

.home-page-faq-title {
  font-size: 24px;
  padding-bottom: 20px;
}

.home-page-faq-description {
  font-size: 60px;
}

.home-page-faq-question-container {
  margin-top: 30px;
}

.home-page-faq-question {
  position: relative;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 21px;
}

.home-page-faq-question img {
  height: 22px;
  position: absolute;
  right: 0;
}

.home-page-faq-answer {
  padding-top: 30px;
  padding-bottom: 15px;
  margin-top: 15px;
  font-size: 16px;
}

.home-page-footer-container {
  height: 518px;
  width: 100%;
  padding: 80px 10% 70px 10%;
}

.home-page-footer-info-container {
  height: 90%;
}

.footer-logo-container img {
  width: 153px;
  margin-top: 10px;
}

.footer-slogan-container {
  margin-top: 20px;
  font-size: 14px;
}

.footer-socials-container {
  margin-top: 90px;
}

.footer-socials-container img {
  height: 33px;
  padding-right: 20px;
}

.side-nav-socials-container {
  padding-top: 20px;
  padding-left: 30px;
}

.side-nav-socials-container img {
  height: 33px;
  padding-right: 34px;
}

.home-page-footer-copyright-container {
  padding-top: 35px;
  text-align: center;
  font-size: 14px;
}

.footer-link-title {
  font-size: 13px;
  padding-bottom: 10px;
}

.footer-link {
  font-size: 14px;
  margin-top: 30px;
}

.home-page-contact-container {
  width: 100%;
  background: url("/public/images/contact_bkg.webp") no-repeat #d9d9d9;
  height: 860px;
}

.home-page-contact-right {
  padding: 5px 20px;
  height: 700px;
}

.home-page-contact-left {
  padding: 80px 80px 0 5px;
  height: 700px;
}

.home-page-contact-box-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.home-page-contact-box {
  position: absolute;
  height: calc(100% - 2px);
  width: 95%;
  margin: 0 auto;
}

.rainbow-glow-container-contact {
  position: absolute;
  filter: blur(15px);
  width: 90%;
  height: 96%;
  margin-top: 4%;
  margin-left: 3%;
}

.home-page-contact-form-privacy-tick {
  margin-top: 40px;
  margin-right: 10px;
}

.home-page-contact-form-submit-button {
  text-align: center;
  width: 100%;
  border-radius: 7px;
  margin: 40px auto;
  padding: 12px 20px;
}

.home-page-contact-title {
  font-size: 36px;
  padding-bottom: 20px;
}

.home-page-contact-description {
  font-size: 20px;
  padding-bottom: 40px;
}

.home-page-our-story-card-backdrop-container {
  height: 662px;
  width: 100vw;
  margin-top: -662px;
}

.home-page-our-story-card-container {
  padding-left: 30px;
  padding-bottom: 30px;
}

.home-page-our-story-card {
  padding: 50px 50px 0 100px;
  border-radius: 26px 0 0 26px;
  height: 762px;
  position: relative;
  z-index: 1;
}

.home-single-report-container {
  width: 100vw;
  height: 498px;
  margin: 140px 0 180px;
}

.home-single-report-content {
  width: 1200px;
  margin: auto;
  display: flex;
}

.home-single-report-button {
  width: 200px;
  height: 44px;
  border-radius: 12px;
  margin-top: 30px;
  text-align: center;
  padding-top: 10px;
}

.home-single-report-text-block {
  margin-left: 60px;
  margin-right: 30px;
  max-width: 400px;
  padding-top: 40px;
}

.home-single-report-title {
  font-size: 40px;
  font-weight: 600;
  padding-top: 100px;
}

.home-single-report-text {
  font-size: 18px;
  padding: 20px 20px 0 0;
  line-height: 28px;
}

.home-single-report-card-container {
  margin-right: 60px;
  margin-top: -40px;
}

.home-single-report-card {
  height: 178px;
  border-radius: 8px;
  margin-bottom: 27px;
  position: relative;
}

.home-single-report-card-logo {
  position: absolute;
  top: 45px;
  left: 45px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.reports-menu {
  margin-left: 75px;
  margin-right: 57px;
  margin-top: 29px;
  width: auto;
  font-size: 16px;
}

.report-menu-item {
  width: 218px;
  height: fit-content;
  font-size: 20px;
  padding: 10px;
  border-radius: 7px;
  overflow: hidden;
  margin-top: 5px;
}

.report-transparent-cover {
  position: fixed;
  height: 400px;
  bottom: 0;
  padding: 77px 140px 70px 90px;
  width: calc(100vw - 649px);
}

.report-description {
  width: 100%;
  font-size: 20px;
  height: fit-content;
  margin-bottom: 30px;
}

.report-download-button:hover,
.report-download-button {
  padding: 15px;
  border-radius: 12px;
  min-width: 300px;
}

.report-download-button img {
  padding-right: 10px;
  max-width: 50px;
}
.home-single-report-card-logo .safe,
.home-single-report-card-logo .folders {
  margin-left: 5px;
  margin-top: 5px;
}

.home-single-report-card-logo .head {
  margin-top: 5px;
}

.home-single-report-card-logo .body {
  margin-top: -5px;
}

.home-single-report-card-text-block {
  margin-left: 150px;
  padding-right: 20px;
}

.home-single-report-card-title {
  padding-top: 30px;
  font-size: 20px;
  font-weight: 600;
}

.home-single-report-card-text {
  font-size: 20px;
  padding-top: 10px;
}

.homepage-dynamic-banner-container {
  margin-bottom: 120px;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
  height: 562px;
}

.home-page-dynamic-banner-opacity-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  backdrop-filter: blur(1.5px);
}

.homepage-dynamic-banner-text-dynamic-container {
  position: absolute;
  top: calc((100% - 175px) / 2);
  left: calc(10% + 90px);
  height: 175px;
  overflow: hidden;
}

.homepage-dynamic-banner-text-static {
  position: absolute;
  top: calc((100% - 44px) / 2);
  left: 10%;
  height: 35px;
  width: 90px;
}

.homepage-dynamic-banner-item,
.homepage-dynamic-banner-text-static {
  font-size: 30px;
}

.homepage-dynamic-banner-text-dynamic-element-container {
  position: relative;
  height: calc(100% - 1px);
  width: 600px;
  overflow-y: hidden;
}

.homepage-dynamic-banner-item {
  position: absolute;
  left: 0;
  width: fit-content;
  height: 35px;
  transition: top 0.5s ease, filter 0.5s ease;
  margin-top: -4px;
  padding-left: 2px;
}

.homepage-dynamic-banner-image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: opacity;
  transition-duration: 1s;
}

.documents-subpage-title {
  margin-top: 90px;
  font-size: 40px;
}

.documents-banking-section-card-container {
  min-width: 350px;
  width: fit-content;
  border-radius: 12px;
  display: flex;
}

.documents-banking-go-to-container {
  width: 175px;
  padding-right: 20px;
}

.documents-banking-go-to-image-container {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 49px auto 0 auto;
}

.documents-banking-go-to-image-container img {
  padding: 15%;
  width: 100%;
}

.documents-banking-go-to-text {
  text-align: center;
  margin-top: 15px;
  font-size: 17px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.guide-article-container {
  padding-top: 10px;
  padding-bottom: 91px;
  margin: 76px auto 0 auto;
}

.guide-page-container,
.blog-article-container,
.blog-page-container {
  padding-top: 91px;
  padding-bottom: 91px;
  margin: 76px auto 0 auto;
}

.blog-article-separator {
  margin-bottom: 70px;
}

.blog-article-container {
  height: fit-content;
}

.blog-page-container {
  width: 1200px;
  margin: 50px auto;
}

.blog-heading {
  font-size: 36px;
}

.guide-heading {
  font-size: 24px;
}

.blog-description {
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 60px;
}

.guide-description {
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 60px;
}

.guide-article-card-container,
.blog-article-card-container {
  margin-bottom: 30px;
  margin-right: 30px;
  height: 580px;
  border-radius: 12px;
  max-width: 350px;
  min-width: 350px;
}

.guide-articles-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
}

.guide-article-card-image-container,
.blog-article-card-image-container {
  height: 50%;
  border-radius: 12px 12px 0 0;
}

.blog-article-card-image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
}

.guide-article-card-image-container img,
.guide-article-image-container img {
  width: 100%;
  height: 100%;
}

.guide-article-card-details-container,
.blog-article-card-details-container {
  height: 50%;
  padding: 32px 24px;
}

.guide-article-card-category,
.blog-article-card-category {
  font-size: 14px;
}

.guide-article-card-title,
.blog-article-card-title {
  width: calc(100% - 60px);
}

.guide-article-card-arrow,
.blog-article-card-arrow {
  margin-left: 30px;
  width: 24px;
  height: 24px;
}

.guide-article-card-arrow img,
.blog-article-card-arrow img {
  width: 100%;
}

.guide-article-card-title-container,
.blog-article-card-title-container {
  font-size: 18px;
  overflow: hidden;
  height: 64px;
  padding: 2px 0;
  display: flex;
}

.guide-article-card-read-article,
.guide-article-card-description,
.blog-article-card-description {
  font-size: 16px;
  height: 144px;
  overflow: hidden;
}

.blog-article {
  width: 996px;
  margin: auto;
  padding-bottom: 60px;
}

.guide-article-image-container,
.blog-article-image-container {
  height: 560px;
  width: 100%;
}

.blog-article-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-article-category {
  padding-top: 18px;
  font-size: 18px;
}

.blog-article-title-container h1 {
  font-size: 24px;
}

.blog-article-title-container {
  padding-top: 21px;
  padding-bottom: 61px;
}

.guide-article-body-container,
.blog-article-body-container {
  padding-top: 40px;
}

.guide-article-body h2,
.guide-article-body,
.blog-article-body h2,
.blog-article-body {
  padding-top: 12px;
  font-size: 16px;
}

.guide-article-body img {
  width: 100%;
}

.guide-article-cards-container {
  padding-top: 50px;
  max-width: 1200px;
}

.blog-article-cards-container {
  width: 1200px;
  margin: 0px auto;
}

.register-subpage-container,
.register-page-container,
.login-page-container,
.mfa-subpage-container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.register-page-progress-container,
.login-page-image-container {
  width: 40%;
  position: relative;
  height: 100vh;
}

.login-page-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.register-page-subpage-container,
.register-page-verification-container,
.mfa-subpage-container {
  width: 60%;
  padding: 100px 130px;
}

.login-image-snapshot-logo {
  position: absolute;
  top: 5%;
  left: 15%;
}

.register-image-snapshot-logo {
  position: absolute;
  top: 10%;
  left: 15%;
}

.register-progress-container {
  position: absolute;
  top: 20%;
  width: 60%;
  height: 60%;
  left: 20%;
  right: 20%;
}

.register-progress-email {
  position: absolute;
  top: 80%;
  width: 60%;
  height: 10%;
  bottom: 10%;
  left: 20%;
  right: 20%;
}

.login-image-quote-container {
  position: absolute;
  bottom: 0;
  padding-bottom: 80px;
  width: 85%;
  left: 7.5%;
  right: 7.5%;
}

.login-image-quote {
  font-size: 40px;
  display: inline-block;
  padding-right: 8px;
}

.login-image-quote span {
  padding: 0 10px;
  width: fit-content;
}

.login-image-quoter,
.login-image-quoter-description {
  font-size: 20px;
}

.register-page-title,
.login-page-title,
.mfa-page-title {
  font-size: 52px;
}

.register-page-description,
.login-page-description,
.mfa-page-description {
  padding-top: 16px;
  font-size: 16px;
  height: fit-content;
}

.quote {
  display: inline;
  padding-right: 8px;
}

.quote-highlight {
  display: inline;
  padding-right: 8px;
}

.register-page-description.two-factor,
.register-page-description.verification {
  padding-top: 10px;
}

.register-page-description.two-factor {
  padding-bottom: 15px;
}

.register-page-subtitle,
.login-page-subtitle {
  padding-top: 41px;
  font-size: 24px;
  padding-bottom: 28px;
  height: fit-content;
}

.register-page-subtitle.two-factor,
.register-page-subtitle.verification {
  padding-bottom: 0;
}

.login-form-element-container,
.register-form-element-container,
.login-form-element-password-container,
.register-form-element-password-container {
  padding-bottom: 20px;
}

.register-form-element-label,
.login-form-element-label,
.login-page-forgot {
  font-size: 14px;
}

.login-form-element-password-container,
.login-form-element-container,
.register-form-element-password-container,
.register-form-element-container {
  position: relative;
}

.login-form-element-password-container input,
.login-form-element-container input,
.register-form-element-password-container input,
.register-form-element-container input {
  margin-top: 6px;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  background: unset;
  font-size: 16px;
  border-radius: 8px;
}

.login-form-element-password-container img,
.register-form-element-password-container img {
  position: absolute;
  right: 20px;
  top: 44%;
}

.login-form-submit-container {
  margin-top: 41px;
}

.two-factor-button-container input,
.verify-submit-button-container input,
.register-form-submit-container input,
.login-form-submit-container input {
  width: 160px;
  height: 55px;
  text-align: center;
  border-radius: 9px;
  border: none;
}

.login-page-forgot {
  width: 100%;
  text-align: end;
}

.login-page-remember-forget-container {
  display: flex;
}

.login-page-remember-container {
  width: 100%;
}

#remember-check {
  margin-right: 10px;
}

#remember-check:checked {
  color: #99a1a7;
}

.two-factor-button-container {
  margin-top: 120px;
}

.two-factor-button-container {
  display: flex;
}

.login-form-info-container {
  display: flex;
  margin-top: 20px;
}

.login-page-no-account {
  display: flex;
  font-size: 14px;
  padding-right: 10px;
}

.login-page-contact-info {
  font-size: 14px;
}

.register-now-link {
  padding-left: 5px;
}

.register-error-message,
.register-message,
.login-error-message,
.mfa-error-message,
.mfa-message {
  font-size: 16px;
  margin-bottom: 20px;
}

.login-page-form-container,
.register-page-form-container {
  width: 60%;
  padding: 100px 130px 50px 130px;
  height: 100vh;
  overflow-y: auto;
}

.register-page-form-container form,
.login-page-form-container form {
  max-width: 500px;
}

.register-section-container {
  display: flex;
}

.register-section-image-container {
  padding-right: 7px;
  width: 24px;
}

.register-section-image-container img {
  width: 100%;
}

.register-section-container {
  padding-top: 15px;
}

.register-section-text-container {
  padding-top: 2px;
}

.register-section-title {
  font-size: 16px;
  opacity: 0.5;
}

.register-section-description {
  font-size: 14px;
  opacity: 0.5;
}

.register-section-title.complete,
.register-section-description.complete {
  opacity: 1;
}

.verify-form-container {
  margin-top: 35px;
  padding-right: 20%;
  height: 80px;
  margin-bottom: 55px;
}

.reset-form-container {
  margin-top: 35px;
  padding-right: 20%;
  margin-bottom: 55px;
}

.verify-form-character {
  width: 11%;
  height: 100%;
  margin-right: 4%;
  font-size: 40px;
  text-align: center;
  border-radius: 6px;
}

.verify-submit-button:disabled {
  cursor: default !important;
}

.verify-resend-code {
  margin-top: 46px;
  font-size: 14px;
}

.return-to-details {
  margin-top: 20px;
  font-size: 14px;
}

.two-factor-no-text {
  margin-left: 30%;
  margin-top: 15px;
  font-size: 14px;
}

.register-access-now-container {
  display: flex;
  padding: 50px 20px;
  width: 70%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.register-access-now-tick {
  width: 33px;
  border-radius: 0 !important;
  margin-right: 31px;
}

.register-access-now-label {
  font-size: 16px;
}

.register-page-advanced-container {
  margin-top: 20px;
  border-radius: 13px;
  height: 70%;
  width: 70%;
  padding: 5px 40px;
  position: relative;
  display: block;
}

.free-plan-button {
  width: 60%;
  margin-left: 5%;
  padding: 5px;
  font-size: 16px;
  border-radius: 6px;
  background: unset;
}

.burger-menu-close {
  width: 100%;
}

.free-plan-button-container {
  margin-top: 30px;
}

.register-plus-feature-text {
  padding-top: 3px;
  padding-left: 12px;
}

.register-plus-feature {
  display: flex;
  margin-top: 7px;
  margin-bottom: 7px;
}

.register-plus-feature img {
  max-height: 27px;
}

.verify-submit-button-container input,
.two-factor-button-container input,
.register-form-submit-container input,
.login-form-submit-container input {
  font-size: 16px;
}

.guide-article-category {
  margin-top: 40px;
  font-size: 18px;
}

.guide-article-title-container {
  margin-top: 21px;
}

.guide-article-title {
  font-size: 24px;
  padding-bottom: 80px;
}

.more-guide-text {
  font-size: 24px;
  padding-bottom: 30px;
}

.your-executors-container {
  margin-top: 10vh;
  min-height: 80vh;
  height: fit-content;
  border-radius: 15px;
}

.executors-title-container {
  padding: 66px 64px 50px 64px;
}

.executors-title {
  font-size: 24px;
}

.profile-dob-input-container {
  margin-top: 5px;
}

.profile-dob-input.day,
.profile-dob-input.month,
.profile-dob-input.year {
  border: 1px solid #282c34;
  border-radius: 5px;
}

.profile-dob-input.day,
.profile-dob-input.month {
  min-width: 40px;
  width: 13%;
}

.profile-dob-input.year {
  min-width: 80px;
  width: 26%;
}

.privacy-title-container {
  padding-top: 80px;
  font-size: 24px;
}

.privacy-body-container {
  font-size: 16px;
  padding-top: 40px;
  padding-right: 120px;
  opacity: 0.8;
  padding-bottom: 100px;
}

.dashboard-col {
  max-width: 162px;
}

.dashboard-knowledge-hub-container {
  padding-top: 80px;
  padding-bottom: 200px;
}

.back-arrow-container {
  height: 25px;
}

.back-arrow-container.link img {
  height: 100%;
}

.side-nav-pop-up-container {
  position: absolute;
  width: 747px;
  height: 559px;
  border-radius: 10px;
  top: calc((100vh - 559px) / 2);
  left: calc((100vw - 747px) / 2);
  z-index: 100;
}

.pop-up-close-container {
  width: 100%;
  padding: 27px 24px 0 24px;
}

.pop-up-close-container img {
  width: 28px;
  height: 28px;
  margin-left: calc(100% - 28px);
}

.feedback-input-container,
.pop-up-title-container {
  padding: 0 57px;
}

.pop-up-description-container {
  padding: 26px 57px;
  font-size: 20px;
}

.popup-title {
  font-size: 26px;
}

.feedback-input {
  width: 97%;
  height: 244px;
  resize: none;
}

.feedback-buttons-container {
  padding: 26px 57px;
  display: flex;
}

.feedback-cancel-button {
  padding-top: 5px;
  padding-right: 30px;
  width: 15%;
  margin-left: calc(45px + 15% + 30%);
}

.feedback-submit-button {
  width: 30%;
  padding: 5px;
  text-align: center;
  height: 40px;
  border-radius: 5px;
  border: none;
}

.feedback-complete-container {
  width: 100%;
  padding: 0 57px 0 30px;
  text-align: center;
  margin-top: 160px;
}

.feedback-complete {
  text-align: center;
  font-size: 20px;
}

.feedback-error {
  max-height: 29px;
  overflow-y: hidden;
}

.invite-error,
.feedback-error {
  padding: 5px 57px;
}

.side-nav-sub-menu-container {
  position: absolute;
  width: 134px;
  top: 65%;
  left: 260px;
  text-align: center;
  padding: 2px 0;
  border-radius: 10px;
  z-index: 10;
}

.side-nav-sub-menu-item {
  padding: 10px 19px;
}

.contact-us-email-container,
.contact-us-phone-number-container {
  width: 100%;
  margin: 30px 57px;
  display: flex;
  font-size: 18px;
}

.email-address,
.phone-number {
  padding-left: 20px;
  padding-top: 3px;
}

.popup-description.center,
.popup-title.center {
  text-align: center;
  width: 60%;
  margin-left: 20%;
}

.invite-others-form-container {
  margin-top: 20px;
  width: 60%;
  margin-left: 20%;
  padding: 14px;
  border-radius: 6px;

  background: rgba(89, 89, 89, 0.53);
}

.invite-others-form-element-container {
  width: 100%;
  height: 65px;
  padding: 7px 0;
  margin-bottom: 15px;
}

.invite-others-form-element-container input {
  width: 100%;
  height: 40px;
  border: none;
  background: inherit;
  font-size: 18px;
}

.invite-others-form-element-label {
  font-size: 14px;
}

.invite-others-form-button {
  margin-top: 40px;
}

.invite-others-form-button {
  width: 40%;
  margin-left: 30%;
  padding: 10px;
  border-radius: 6px;
  border: none;
}

.anchor {
  position: relative;
  top: -125px;
  visibility: hidden;
}

.home-page-separator {
  height: 2px;
  width: 400px;
  margin: 0 auto 100px auto;
}

.page-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  cursor: default;
}

.dialog-container {
  position: fixed;
  top: 20%;
  left: calc(50vw - 200px);
  width: 400px;
  margin: auto;
  min-height: 200px;
  z-index: 100;
  border-radius: 10px;
}

.dialog-button {
  width: fit-content;
  height: 42px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0 10px 0;
  margin: 20px auto;
}

.dialog-button-text {
  padding: 12px 30px;
}

.dialog-header {
  padding: 40px 40px 20px 40px;
  font-size: 28px;
  font-family: aktivGroteskBold;
}

.dialog-text {
  padding: 0px 40px 20px 40px;
  font-size: 14px;
}

.dialog-footer-container {
  display: flex;
  margin-top: 20px;
}

.dialog-header img {
  width: 40px;
  margin-right: 20px;
}

.dialog-content-heading {
  padding: 20px 40px;
  font-size: 20px;
}

.header-container {
  height: 80px;
  width: 100%;
}

.header-logo-container {
  padding: 24px 57px;
}

.large-header {
  display: block;
}

.small-header {
  display: none;
}

.register-plus-features-container {
  height: fit-content;
}

.register-plus-yearly-container,
.register-plus-monthly-container,
.register-plus-price-container {
  display: flex;
}

.register-plus-pricing-container {
  left: 40px;
  right: 5px;
  bottom: 35px;
  width: calc(100% - 80px);
  position: absolute;
  display: block;
}

.register-plus-yearly-container {
  margin-top: 18px;
}

.register-period-radio {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.register-plus-type {
  padding-left: 10px;
  width: fit-content;
  font-size: 16px;
}

.register-plus-price {
  padding-right: 5px;
  font-size: 16px;
}

.register-plus-period {
  font-size: 14px;
}

.register-plus-price-container {
  margin-left: auto;
}

.plus-subscribe-button {
  width: 100%;
  margin-top: 20px;
  padding: 10px 0;
  border-radius: 6px;
  border: none;
  font-size: 16px;
}

.profile-membership-type-container {
  display: flex;
}

.profile-membership-type-label {
  padding-right: 10px;
  font-size: 18px;
}

.profile-membership-button-container,
.profile-membership-content-container {
  padding: 0 50px 30px 50px;
  display: flex;
}

.profile-plus-payment-information {
  margin-left: auto;
  margin-right: 50px;
  font-size: 16px;
}

.profile-membership-type {
  font-size: 18px;
}

.profile-plus-upgrade {
  border: none;
  border-radius: 6px;
  padding: 14px 10px 10px 10px;
  width: 280px;
}

.plus-subscription-banner-container {
  position: absolute;
  width: 80%;
  margin-left: 10%;
  z-index: 10;
  border-radius: 19px;
  padding: 40px;
}

.plus-subscription-access-container {
  border-radius: 13px;
  padding: 35px;
  position: relative;
}

.plus-subscription-banner-content {
  display: flex;
  width: 100%;
}

.plus-subscription-type-content,
.plus-subscription-features-content {
  width: 50%;
  padding: 10px 50px;
}

.plus-subscription-cancel-anytime {
  font-size: 16px;
  margin-top: 13px;
  opacity: 80%;
}

.plus-subscription-type-divider {
  opacity: 80%;
  margin-top: 10px;
}

.plus-subscription-type-title-container {
  display: flex;
  margin-bottom: 30px;
}

.plus-subscription-type-price {
  font-size: 38px;
}

.plus-subscription-type-label {
  font-size: 16px;
  margin-top: 22px;
  padding-left: 5px;
}

.plus-subscription-banner-title {
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 13px;
  text-align: center;
}

.plus-subscription-banner-description {
  text-align: center;
  font-size: 18px;
  margin-bottom: 50px;
}

.plus-subscription-close img {
  width: 20px;
  position: absolute;
  top: 30px;
  right: 35px;
}
.mobile-accounts-cards-container {
  display: none;
}
